<template>
  <b-card no-body class="p-2">
    <h3 class="mb-2">
      {{
        hability.habilidadId == 0
          ? $t("Routes.HabilityAdd")
          : $t("Routes.HabilityEdit")
      }}
    </h3>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- name work area field -->
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.hability.columns.name')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.hability.columns.name')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input v-model="hability.nombre" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.hability.columns.type')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.hability.columns.type')"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="usuario"
                  v-model="hability.habilidadTipoId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="texto"
                  :options="typeHabilities"
                  :reduce="(option) => option.id"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-row>
              <b-col>
                <b-button
                  v-b-modal.habilityType
                  variant="primary"
                  class="float-right"
                >
                  {{ $t('Routes.HabilityTypeAdd') }}
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <!-- Description Field -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.hability.columns.description')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-textarea v-model="hability.descripcion" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="float-right mt-2 mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <b-spinner v-if="addingHability" small class="mr-1" />
            {{ hability.habilidadId == 0 ? $t("Lists.Add") : $t("Lists.Edit") }}
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
    <b-modal
      id="habilityType"
      ref="habilityType"
      :title="$t('Routes.HabilityTypeAdd')"
      size="lg"
      :hide-footer="true"
    >
      <HabilityTypeAdd
        :modal-call="true"
        @find-records="getHabiltiesTypes"
      />
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,

  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import humanResources from '@/services/humanresources.service'
import optionService from '@/services/option.service'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import router from '@/router'
import { ref } from '@vue/composition-api'
import HabilityTypeAdd from '../hability-type/HabilityTypeAddEdit.vue'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BFormTextarea,
    BButton,

    ValidationProvider,
    ValidationObserver,

    vSelect,
    HabilityTypeAdd,
  },
  setup() {
    const habilityType = ref(null)
    const typeHabilities = ref([])
    const addingHability = ref(false)
    const hability = ref({
      habilidadId: 0,
      habilidadTipoId: 0,
      nombre: '',
      descripcion: '',
      archivado: false,
    })

    const blankHability = {
      habilidadId: 0,
      habilidadTipoId: 0,
      nombre: '',
      descripcion: '',
      archivado: false,
    }

    const {
      fetchHability,
      createHability,
      updateHability,
    } = humanResources()

    const {
      fetchOptions,
    } = optionService()

    const resetHability = () => {
      hability.value = JSON.parse(JSON.stringify(blankHability))
    }

    if (router.currentRoute.params.habilidadId) {
      fetchHability(router.currentRoute.params.habilidadId, data => {
        hability.value = data
      })
    } else {
      hability.value = JSON.parse(JSON.stringify(blankHability))
    }
    fetchOptions({ nombreDominio: 'RecursosHumanosHabilidadTipo' }, data => {
      typeHabilities.value = data
    })
    const getHabiltiesTypes = () => {
      habilityType.value.hide()
      fetchOptions({ nombreDominio: 'RecursosHumanosHabilidadTipo' }, data => {
        typeHabilities.value = data
      })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetHability)

    const onSubmit = () => {
      addingHability.value = true
      if (hability.value.habilidadId === 0) {
        createHability(hability.value, () => {
          addingHability.value = false
          router.push({ name: 'apps-human-resources-hability-list' })
        })
      } else {
        updateHability(hability.value.habilidadId, hability.value, () => {
          addingHability.value = false
          router.push({ name: 'apps-human-resources-hability-list' })
        })
      }
    }

    return {
      typeHabilities,
      hability,
      addingHability,
      required,
      getValidationState,
      refFormObserver,
      resetForm,
      onSubmit,
      habilityType,
      getHabiltiesTypes,

    }
  },
}
</script>
