var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-2",attrs:{"no-body":""}},[_c('h3',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.hability.habilidadId == 0 ? _vm.$t("Routes.HabilityAdd") : _vm.$t("Routes.HabilityEdit"))+" ")]),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('humanResources.hability.columns.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('humanResources.hability.columns.name'),"state":errors.length > 0 ? false : null}},[_c('b-form-input',{model:{value:(_vm.hability.nombre),callback:function ($$v) {_vm.$set(_vm.hability, "nombre", $$v)},expression:"hability.nombre"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('humanResources.hability.columns.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('humanResources.hability.columns.type'),"state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"usuario","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"texto","options":_vm.typeHabilities,"reduce":function (option) { return option.id; }},model:{value:(_vm.hability.habilidadTipoId),callback:function ($$v) {_vm.$set(_vm.hability, "habilidadTipoId", $$v)},expression:"hability.habilidadTipoId"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.habilityType",modifiers:{"habilityType":true}}],staticClass:"float-right",attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Routes.HabilityTypeAdd'))+" ")])],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('humanResources.hability.columns.description'),"state":errors.length > 0 ? false : null}},[_c('b-form-textarea',{model:{value:(_vm.hability.descripcion),callback:function ($$v) {_vm.$set(_vm.hability, "descripcion", $$v)},expression:"hability.descripcion"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"float-right mt-2 mb-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[(_vm.addingHability)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.hability.habilidadId == 0 ? _vm.$t("Lists.Add") : _vm.$t("Lists.Edit"))+" ")],1)],1)],1)]}}])}),_c('b-modal',{ref:"habilityType",attrs:{"id":"habilityType","title":_vm.$t('Routes.HabilityTypeAdd'),"size":"lg","hide-footer":true}},[_c('HabilityTypeAdd',{attrs:{"modal-call":true},on:{"find-records":_vm.getHabiltiesTypes}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }